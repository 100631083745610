import { ProduceAvailability, Quantities } from '../store/inventoryApi'
import { Region } from '../../region/types'
import { CSmartTable } from '@coreui/react-pro'
import format from 'date-fns/format'
import { nb } from 'date-fns/locale'
import { AvailabilityProgressBar } from './AvailabilityProgressBar'

export function ProduceProductAvailabilities({ item, regions }: { item: ProduceAvailability; regions: Region[] }) {
    // map availability to be grouped by date
    const mappedAvailability: Record<string, Record<string, Quantities>> = {}
    if (item.data) {
        for (const regionId in item.data) {
            for (const date in item.data[regionId].availability) {
                if (!mappedAvailability[date]) {
                    mappedAvailability[date] = {}
                }
                mappedAvailability[date][regionId] = item.data[regionId].availability[date]
            }
        }
    }

    return (
        <CSmartTable
            itemsPerPage={100}
            items={Object.entries(mappedAvailability)}
            tableProps={{
                responsive: true,
                striped: true,
                hover: true,
            }}
            columns={[
                {
                    key: '0',
                    label: 'Dato',
                    _style: { width: '15%' },
                },
                {
                    key: '1',
                    label: 'Dag',
                    _style: { width: '10%' },
                },
                {
                    key: '',
                    children: regions.map((region) => {
                        return {
                            key: region.name,
                            label: region.name,
                            _props: { scope: 'regions' },
                            _style: { width: `${75 / regions.length}%` },
                        }
                    }),
                },
            ]}
            scopedColumns={{
                0: (item: [string, Quantities]) => {
                    const date = format(new Date(item[0]), 'd. MMMM', { locale: nb })
                    const year = format(new Date(item[0]), 'yyyy')
                    return (
                        <td className={'font-monospace h-100 align-middle'}>
                            <span>{date}</span>

                            <br />
                            <span style={{ fontSize: '12px', color: '#6c757d' }}>{year}</span>
                        </td>
                    )
                },
                1: (item: [string, Quantities]) => {
                    const day = new Date(item[0]).toLocaleDateString('nb-NO', {
                        weekday: 'long',
                    })
                    return <td>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                },
                ...Object.fromEntries(
                    regions.map((region) => {
                        return [
                            region.name,
                            (item: [string, Quantities[]]) => {
                                const theItem = item[1]
                                const quantity = theItem?.[region.id] ?? {
                                    reserved: 0,
                                    capacity: 0,
                                }
                                return (
                                    <td
                                        style={{
                                            padding: '10px',
                                        }}
                                    >
                                        <div>
                                            <AvailabilityProgressBar
                                                reservedQuantity={quantity.reserved}
                                                availableQuantity={quantity.capacity}
                                            />
                                        </div>
                                    </td>
                                )
                            },
                        ]
                    })
                ),
            }}
        />
    )
}
